<template>
  <div>
    <div class="banner cooperation-banner">
      <div class="banner-item">{{ item }}</div>
    </div>
    <div class="container">
      <div class="service">
        <div class="service-title-sm item-color">推广</div>
        <div class="service-title">商业案例及合作</div>
        <div class="row-between" style="flex-wrap: wrap">
          <div
            class="service-extend"
            v-for="item in serviceExtend"
            :key="item.id"
          >
            <div class="service-extend-img"><img :src="item.img" alt="" /></div>
            <div class="service-extend-t1 show-title-third">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>

      <div class="service">
        <div class="service-title-sm item-color">运营实景</div>
        <div class="service-title">智能回收设备社区实景图</div>
        <div class="service-p">
          <p>
            小松鼠市场运营负责人及社区宣导人员及社区居民接受采访。东方电视台对小松鼠给予高度赞赏，并希望将
          </p>
          <p>这种真正服务于社区的惠民环保行动推广到越来越多的地区。</p>
        </div>
        <div class="service-img">
          <img src="../../assets/img/service-2.png" alt="" />
        </div>
        <div class="service-title" style="margin-top: 100px">
          智能垃圾厢房及中转站运营实景图
        </div>
        <div class="service-p">
          <p>
            小松鼠是一个可为社区/街道/政府机构/公共场所提供垃圾分类的综合服务商，通过物联网和互联网技术来优化
          </p>
          <p>
            现有的垃圾分类收集、分类运输、分类处置的体系，通过包含智能化软硬件产品、宣导活动、清运车辆、分拣中心及数据大屏和运
          </p>
          <p>
            营团队一整套垃圾分类服务。发挥互联网及智能化特点，降低人力资源成本，提高运营效率，实现垃圾的减量化。
          </p>
        </div>
        <div class="row-between" style="flex-wrap: wrap; margin-top: -30px">
          <div class="service-extend" v-for="item in serviceBus" :key="item.id">
            <div class="service-extend-img service-extend-img-sp">
              <img :src="item.img" alt="" />
            </div>
            <div class="service-extend-t1 show-title-third">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>

      <div class="service">
        <div class="service-title-sm item-color">覆盖范围</div>
        <div class="service-title">小松鼠回收机上海铺设情况</div>
        <div class="row-between">
          <div class="service-extend" v-for="item in layData" :key="item.id">
            <div class="service-extend-img"><img :src="item.img" alt="" /></div>
            <div class="service-extend-t1 show-title-third">
              {{ item.name }}
            </div>
            <div class="service-desc">
              <p>已铺设完成<span class="service-desc-color">500</span>台设备</p>
              <p>覆盖<span class="service-desc-color">200</span>万人口</p>
            </div>
          </div>
        </div>
      </div>

      <div class="service">
        <div class="service-title" style="margin-top: 60px">
          其他城市合作情况
        </div>
        <div style="margin: 30px 0">现已开启全国联营商招募</div>
        <div class="service-img">
          <img src="../../assets/img/cooperation/cooperation-15.png" alt="" />
        </div>
      </div>

      <div style="height: 238px"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      serviceExtend: [
        {
          id: 0,
          name: "蓝月亮x小松鼠品牌合作推广",
          img: require("../../assets/img/cooperation/cooperation-1.png"),
        },
        {
          id: 1,
          name: "彩云之南x小松鼠惠民旅游",
          img: require("../../assets/img/cooperation/cooperation-2.png"),
        },
        {
          id: 2,
          name: "上海师范大学环保宣传会",
          img: require("../../assets/img/cooperation/cooperation-3.png"),
        },
        {
          id: 3,
          name: "蓝月亮x小松鼠火热售卖现场",
          img: require("../../assets/img/cooperation/cooperation-4.png"),
        },
        {
          id: 4,
          name: "彩云之南x玉龙雪山公益游现场",
          img: require("../../assets/img/cooperation/cooperation-5.png"),
        },
        {
          id: 5,
          name: "小松鼠助力武汉疫情",
          img: require("../../assets/img/cooperation/cooperation-6.png"),
        },
      ],
      serviceBus: [
        {
          id: 0,
          name: "垃圾厢房改造效果实景图",
          img: require("../../assets/img/cooperation/cooperation-8.png"),
        },
        {
          id: 1,
          name: "顾村中转站墙绘",
          img: require("../../assets/img/cooperation/cooperation-9.png"),
        },
        {
          id: 2,
          name: "垃圾厢房改造效果实景图",
          img: require("../../assets/img/cooperation/cooperation-10.png"),
        },
        {
          id: 3,
          name: "顾村中转站墙绘",
          img: require("../../assets/img/cooperation/cooperation-11.png"),
        },
      ],
      layData: [
        {
          id: 0,
          name: "顾村",
          img: require("../../assets/img/cooperation/cooperation-12.png"),
          
        },
        {
          id: 1,
          name: "崇明",
          img: require("../../assets/img/cooperation/cooperation-13.png"),
        },
        {
          id: 2,
          name: "友谊路",
          img: require("../../assets/img/cooperation/cooperation-14.png"),
        },
      ],
      item: "合作案例",
    };
  },

  mounted: function () {},
  methods: {},
};
</script>

<style>
.cooperation-banner {
  background-image: url("../../assets/img/cooperation/cooperation-banner.png");
}
.service-desc{
  text-align: left;
  line-height: 36px;
  margin-top: 10px;
}
.service-desc-color{
  color: #E9852D;
  font-size: 20px;
}
</style>